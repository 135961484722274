import { useEffect, useState } from "react";
import { VALIDATOR } from "../constants";

const usePasswordValidation = (password: string) => {
  const [passwordError, setPasswordError] = useState<string[] | undefined>();

  const validatePassword = async () => {
    const errors = (await VALIDATOR.password
      .validate(password, { abortEarly: false })
      .then(() => {})
      .catch((err) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return err.inner.reduce((obj: any, e: any) => {
          if (!("password" in obj)) obj.password = [];
          obj.password = obj.password.concat(`· ${e?.errors}`);
          return obj;
        }, {});
      })) as { password: string[] };
    const filteredErrors = errors?.password?.filter(
      (item) =>
        item !== "· required" &&
        item !==
          "· Password must have a minimum of 8 characters and must contain:"
    );
    if (filteredErrors?.length || password.length < 8) {
      filteredErrors.unshift(
        "Password must have a minimum of 8 characters and must contain:"
      );
    }
    setPasswordError(filteredErrors);
  };

  useEffect(() => {
    validatePassword();
  }, [password]);

  return passwordError;
};

export default usePasswordValidation;
