import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import {
  PaginationButtons,
  SearchInput,
  UserFilter,
  UserItem,
} from "../../../components";
import { useSearchParams } from "react-router-dom";
import clsx from "clsx";
import { ArrowSmallDownIcon, FilterIcon } from "../../../assets";
import { PulseLoader } from "react-spinners";
import { getUsers } from "../../../services/users.service";
import { useDebounce, useOutsideClick } from "../../../hooks";
import { IUser } from "../../../models";

const usersLimit = 20;

const Users: FC = (): JSX.Element => {
  const [search, setSearch] = useState("");
  const filterRef = useRef<HTMLDivElement>(null);
  const debounceSearch = useDebounce(search, 600);

  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pagesCount, setPagesCount] = useState(0);
  const [filterGender, setFilterGender] = useState<any[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [nameSort, setNameSort] = useState(0);
  const [genderSort, setGenderSort] = useState(0);
  const [emailSort, setEmailSort] = useState(0);
  const [dateSort, setDateSort] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();

  useOutsideClick(filterRef, () => setIsOpenFilter(false));

  const onChangePage = (page: number) => {
    searchParams.set("page", page?.toString());
    setSearchParams(searchParams, { replace: true });
  };

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    onChangePage(1);
    setSearch(e.target.value);
  };
  const page = searchParams.get("page");

  const onGetUsers = async () => {
    try {
      setIsLoading(true);
      const page = searchParams.get("page");

      const { data } = await getUsers(
        debounceSearch,
        page ? +page - 1 : 0,
        usersLimit,
        filterGender,
        nameSort !== 0
          ? "fullName"
          : genderSort !== 0
          ? "gender"
          : emailSort !== 0
          ? "email"
          : dateSort !== 0
          ? "onboardingAt"
          : null,
        nameSort !== 0
          ? nameSort
          : genderSort !== 0
          ? genderSort
          : emailSort !== 0
          ? emailSort
          : dateSort !== 0
          ? dateSort
          : null
      );
      if (data?.success) {
        setUsers(data?.data?.users);
        setPagesCount(Math.ceil(data?.data?.count / usersLimit));
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onGetUsers();
  }, [debounceSearch, filterGender, nameSort, genderSort, emailSort, dateSort]);

  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        <h4 className={styles.title}>Users</h4>
        <div className={styles.headerAddButton}></div>
      </header>

      <section className={styles.contentContainer}>
        <div className={styles.contentContainerHeader}>
          <div className={styles.searchContainer}>
            <SearchInput
              placeholder="Search"
              value={search}
              onChange={onChangeSearch}
            />
          </div>
          <div ref={filterRef}>
            <div
              onClick={() => setIsOpenFilter((prev) => !prev)}
              className={clsx(styles.filterButton, {
                [styles.activeFilterButton]: isOpenFilter,
              })}
            >
              <FilterIcon />
            </div>
            {isOpenFilter && (
              <UserFilter
                setIsOpen={setIsOpenFilter}
                filterGender={filterGender}
                setFilterGender={(genders) => {
                  onChangePage(1);
                  setFilterGender(genders);
                }}
              />
            )}
          </div>
        </div>

        <div className={styles.sectionHeader}>
          <div
            onClick={() => {
              setDateSort(0);
              setGenderSort(0);
              setEmailSort(0);
              setNameSort((prev) => {
                if (prev === 0) {
                  return -1;
                } else if (prev === -1) {
                  return 1;
                } else return 0;
              });
            }}
            className={clsx(styles.nameBlock, {
              [styles.activeSortValue]: nameSort !== 0,
            })}
          >
            <p>User</p>{" "}
            <ArrowSmallDownIcon
              className={clsx(styles.sectionHeaderArrow, {
                [styles.sectionHeaderArrowUp]: nameSort === -1,
              })}
            />
          </div>
          <div
            onClick={() => {
              setDateSort(0);
              setNameSort(0);
              setEmailSort(0);
              setGenderSort((prev) => {
                if (prev === 0) {
                  return -1;
                } else if (prev === -1) {
                  return 1;
                } else return 0;
              });
            }}
            className={clsx(styles.genderBlock, {
              [styles.activeSortValue]: genderSort === -1,
            })}
          >
            <p>Gender</p>{" "}
            <ArrowSmallDownIcon
              className={clsx(styles.sectionHeaderArrow, {
                [styles.sectionHeaderArrowUp]: genderSort === -1,
              })}
            />
          </div>
          <div
            onClick={() => {
              setDateSort(0);
              setGenderSort(0);
              setNameSort(0);
              setEmailSort((prev) => {
                if (prev === 0) {
                  return -1;
                } else if (prev === -1) {
                  return 1;
                } else return 0;
              });
            }}
            className={clsx(styles.deficitBlock, {
              [styles.activeSortValue]: emailSort === -1,
            })}
          >
            <p>Email</p>{" "}
            <ArrowSmallDownIcon
              className={clsx(styles.sectionHeaderArrow, {
                [styles.sectionHeaderArrowUp]: emailSort === -1,
              })}
            />
          </div>

          <div
            onClick={() => {
              setEmailSort(0);
              setGenderSort(0);
              setNameSort(0);
              setDateSort((prev) => {
                if (prev === 0) {
                  return -1;
                } else if (prev === -1) {
                  return 1;
                } else return 0;
              });
            }}
            className={clsx(styles.dateBlock, {
              [styles.activeSortValue]: dateSort === -1,
            })}
          >
            <p>Registration date</p>{" "}
            <ArrowSmallDownIcon
              className={clsx(styles.sectionHeaderArrow, {
                [styles.sectionHeaderArrowUp]: dateSort === -1,
              })}
            />
          </div>
        </div>

        <div className={styles.listContainer}>
          {isLoading ? (
            <div className={styles.loaderWrapper}>
              <PulseLoader color={"#7D4AFB"} />
            </div>
          ) : (
            users.map((item, index) => <UserItem key={index} item={item} />)
          )}
        </div>
        <PaginationButtons
          pagesCount={pagesCount}
          page={page ? +page : 1}
          onPress={(index) => {
            onChangePage(index);
            onGetUsers();
          }}
        />
      </section>
    </div>
  );
};

export default Users;
