import { AxiosResponse } from "axios";
import axiosInstance from "../apis";

export const getCategories = (search: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const params: any = {
      limit: 1000,
    };

    if (search) {
      params.value = search;
    }

    axiosInstance
      .get("admin/category", { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const createCategory = (value: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      value,
    };

    axiosInstance
      .post("admin/category", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const updateCategory = (
  oldValue: string,
  newValue: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      oldValue,
      newValue,
    };

    axiosInstance
      .put("admin/category", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const deleteCategory = (id: number): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`admin/category/id/${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};
