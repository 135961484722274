import axiosInstance from "../apis";
import { AxiosResponse } from "axios";

export const getAllStaff = async (
  value: string,
  limit: number,
  page: number,
  sortValue: "fullName" | "email" | "createdAt" | "type" | null,
  order: "asc" | "desc"
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let params: any = {
      value,
      limit,
      page,
    };

    if (sortValue) {
      params.sortValue = sortValue;
      params.order = order;
    }

    axiosInstance
      .get("admin/internal/search", { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const createStaff = (
  email: string,
  password: string,
  fullName: string,
  type: string,
  image: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body: any = {
      email,
      password,
      fullName,
      type,
    };
    if (image) {
      body.image = image;
    }
    axiosInstance
      .post("admin/internal", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const updateStaff = (body: {
  email?: string;
  password?: string;
  fullName?: string;
  type?: string;
  image?: string;
  adminId: string;
}): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .put("admin/internal", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const deleteStaff = (id: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`admin/internal/id/${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
