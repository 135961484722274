import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { PulseLoader } from "react-spinners";
import {
  AllRecipesFilter,
  Button,
  PaginationButtons,
  SearchInput,
} from "../../../components";
import { AddSquareIcon, ArrowSmallDownIcon, FilterIcon } from "../../../assets";
import RecipeItem from "../../../components/RecipeItem";
import clsx from "clsx";
import { useDebounce, useOutsideClick } from "../../../hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getRecipes } from "../../../services/recipe.service";
import { IRecipe } from "../../../models";

const pageLimit = 20;

const AllRecipes: FC = (): JSX.Element => {
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [recipes, setRecipes] = useState<IRecipe[]>([]);
  const [pagesCount, setPageCount] = useState(0);
  const [filterSelectedTags, setFilterSelectedTags] = useState<string[]>([]);
  const [filterSelectedCategories, setFilterSelectedCategories] = useState<
    string[]
  >([]);
  const [count, setCount] = useState(0);
  const [nameSort, setNameSort] = useState<-1 | 1 | 0>(0);
  const [categorySort, setCategorySort] = useState<-1 | 1 | 0>(0);

  const [searchParams, setSearchParams] = useSearchParams();

  const onChangePage = (page: number) => {
    searchParams.set("page", page?.toString());
    setSearchParams(searchParams, { replace: true });
  };
  const page = searchParams.get("page");

  const debounceSearch = useDebounce(search, 600);
  const navigation = useNavigate();

  const filterRef = useRef<HTMLDivElement>(null);
  useOutsideClick(filterRef, () => setIsOpenFilter(false));

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    onChangePage(1);
    setSearch(e.target.value);
  };

  const onGetRecipes = async (
    page: number,
    search: string = debounceSearch,
    abortController?: AbortController
  ) => {
    try {
      setIsLoading(true);

      const { data } = await getRecipes(
        page - 1,
        pageLimit,
        search,
        filterSelectedCategories,
        filterSelectedTags,
        nameSort !== 0 ? "name" : categorySort !== 0 ? "category" : null,
        nameSort !== 0 ? nameSort : categorySort !== 0 ? categorySort : null,
        abortController
      );

      if (data?.success) {
        setRecipes(data?.data?.recipes);
        setCount(data?.data?.count || 0);
        setPageCount(Math.ceil(data?.data?.count / pageLimit));
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const abort = new AbortController();

    const page = searchParams.get("page");
    onGetRecipes(page ? +page : 1, debounceSearch, abort);

    return () => abort.abort();
  }, [
    debounceSearch,
    filterSelectedCategories,
    filterSelectedTags,
    nameSort,
    categorySort,
  ]);

  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        <h4 className={styles.title}>
          All Meals{" "}
          {!!count && <span className={styles.countRecipe}>({count})</span>}
        </h4>
        <div className={styles.headerAddButton}>
          <Button
            onClick={() => navigation("/create-recipe")}
            title={"Create New Recipe"}
            leftIcon={<AddSquareIcon />}
            styleType="filled"
            size="small"
          />
        </div>
      </header>

      <section className={styles.contentContainer}>
        <div className={styles.contentContainerHeader}>
          <div className={styles.searchContainer}>
            <SearchInput
              placeholder="Search"
              value={search}
              onChange={onChangeSearch}
            />
          </div>
          <div ref={filterRef}>
            <div
              onClick={() => setIsOpenFilter((prev) => !prev)}
              className={clsx(styles.filterButton, {
                [styles.activeFilterButton]: isOpenFilter,
              })}
            >
              <FilterIcon />
            </div>
            {isOpenFilter && (
              <AllRecipesFilter
                filterSelectedCategories={filterSelectedCategories}
                filterSelectedTags={filterSelectedTags}
                setFilterSelectedCategories={(categories) => {
                  onChangePage(1);
                  setFilterSelectedCategories(categories);
                }}
                setFilterSelectedTags={(tags) => {
                  onChangePage(1);

                  setFilterSelectedTags(tags);
                }}
                setIsOpen={setIsOpenFilter}
              />
            )}
          </div>
        </div>

        <div className={styles.sectionHeader}>
          <div
            onClick={() => {
              setCategorySort(0);
              setNameSort((prev) => {
                if (prev === 0) {
                  return -1;
                } else if (prev === -1) {
                  return 1;
                } else return 0;
              });
            }}
            className={clsx(styles.nameBlock, {
              [styles.activeSortValue]: nameSort !== 0,
            })}
          >
            <p>Meal</p>{" "}
            <ArrowSmallDownIcon
              className={clsx(styles.sectionHeaderArrow, {
                [styles.sectionHeaderArrowUp]: nameSort === -1,
              })}
            />
          </div>
          <div
            onClick={() => {
              setNameSort(0);
              setCategorySort((prev) => {
                if (prev === 0) {
                  return -1;
                } else if (prev === -1) {
                  return 1;
                } else return 0;
              });
            }}
            className={clsx(styles.categoryBlock, {
              [styles.activeSortValue]: categorySort !== 0,
            })}
          >
            <p>Category</p>{" "}
            <ArrowSmallDownIcon
              className={clsx(styles.sectionHeaderArrow, {
                [styles.sectionHeaderArrowUp]: categorySort === -1,
              })}
            />
          </div>
          <div
            onClick={() => {}}
            className={clsx(styles.tagsBlock, {
              // [styles.dateBlockActive]: sort === 1,
            })}
          >
            <p>Tags</p>
          </div>
        </div>

        <div className={styles.listContainer}>
          {isLoading ? (
            <div className={styles.loaderWrapper}>
              <PulseLoader color={"#7D4AFB"} />
            </div>
          ) : (
            recipes.map((item, index) => (
              <RecipeItem
                item={item}
                onGetRecipes={(page: number, search?: string) => {
                  onGetRecipes(page, search);
                  setSearch("");
                }}
                key={`${item.id}_${index}`}
              />
            ))
          )}
        </div>
        <PaginationButtons
          pagesCount={pagesCount}
          page={page ? +page : 1}
          onPress={(index) => {
            onChangePage(index);

            onGetRecipes(index, debounceSearch);
          }}
        />
      </section>
    </div>
  );
};

export default AllRecipes;
