import axiosInstance from "../apis";
import { AxiosResponse } from "axios";

export const getUsers = (
  value: string,
  page: number,
  limit: number,
  gender: string[],
  sortValue: string | null,
  order: number | null
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let params: any = {
      page,
      limit,
    };
    if (gender?.length) {
      params.gender = gender?.join(",");
    }

    if (sortValue) {
      params.sortValue = sortValue;
    }
    if (value) {
      params.value = value;
    }

    if (order) {
      params.order = order === 1 ? "desc" : "asc";
    }
    axiosInstance
      .get("admin/user/search", { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getUserById = (id: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`admin/user/id/${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getUserLogs = (
  id: string,
  from: string,
  to: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const params = {
      from,
      to,
    };
    axiosInstance
      .get(`admin/users/id/${id}/logs`, { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getUserDayStats = (
  id: string,
  date: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let params = {
      date,
    };
    axiosInstance
      .get(`users/${id}/stats/day`, { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getUserWeeklyStats = (
  id: string,
  date: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let params = {
      date,
    };
    axiosInstance
      .get(`users/${id}/stats/week`, { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getUserMonthStats = (
  id: string,
  date: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let params = {
      date,
    };
    axiosInstance
      .get(`users/${id}/stats/month`, { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getUserHalfYearStats = (
  id: string,
  date: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let params = {
      date,
    };
    axiosInstance
      .get(`users/${id}/stats/half`, { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getUserYearStats = (
  id: string,
  date: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let params = {
      date,
    };
    axiosInstance
      .get(`users/${id}/stats/year`, { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getUserConsistenctStreak = (
  id: string,
  date: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let params = {
      date,
    };

    axiosInstance
      .get(`users/${id}/stats/streak`, { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const deleteUser = (id: number): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`admin/user/id/${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const blockUser = (
  userId: number,
  reason: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      userId,
      reason,
    };
    axiosInstance
      .post("admin/user/block", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const unBlockUser = (userId: number): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      userId,
    };
    axiosInstance
      .post("admin/user/unblock", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};
