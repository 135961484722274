import {
  AlcoholIcon,
  BakingIcon,
  BeansIcon,
  BeveragesIcon,
  CondimentsIcon,
  CrossfitIcon,
  CyclingIcon,
  DairyIcon,
  EllipticalIcon,
  FastFoodIcon,
  FishIcon,
  FlavoringsIcon,
  FruitsIcon,
  GrainsIcon,
  HikingIcon,
  HitIcon,
  MeatIcon,
  MushroomsIcon,
  NutsIcon,
  OilsIcon,
  OtherIcon,
  PastaIcon,
  PreservationsIcon,
  ProteinsIcon,
  RowingIcon,
  RunningIcon,
  SaladsIcon,
  SnacksIcon,
  SoupsIcon,
  SpicesIcon,
  StairsMasterIcon,
  SweetnersIcon,
  SwimmingIcon,
  VegetablesIcon,
  WalkingIcon,
  YogaIcon,
} from "../assets";
import * as yup from "yup";
import {
  NO_SPACES_REGEX,
  AT_LEAST_ONE_LOWERCASE_REGEX,
  AT_LEAST_ONE_UPPERCASE_REGEX,
  AT_LEAST_ONE_NUMBER_REGEX,
  AT_LEAST_ONE_SPECIAL_CHARACTER_REGEX,
} from "./regexes";

export const INGREDIENT_CATEGORIES = {
  "Beans & Legumes": <BeansIcon />,
  Beverages: <BeveragesIcon />,
  "Breads & Cereals": <GrainsIcon />,
  "Cheese, Milk & Dairy": <DairyIcon />,
  Eggs: <ProteinsIcon />,
  "Fast Food": <FastFoodIcon />,
  "Fish & Seafood": <FishIcon />,
  Fruit: <FruitsIcon />,
  Meat: <MeatIcon />,
  "Nuts & Seeds": <NutsIcon />,
  "Pasta, Rice & Noodles": <PastaIcon />,
  Salads: <SaladsIcon />,
  "Sauces, Spices & Spreads": <SpicesIcon />,
  Snacks: <SnacksIcon />,
  Soups: <SoupsIcon />,
  "Sweets, Candy & Desserts": <SweetnersIcon />,
  Vegetables: <VegetablesIcon />,
  Other: <OtherIcon />,
};

export const ACTIVITIES = {
  Running: <RunningIcon />,
  Hiking: <HikingIcon />,
  Cycling: <CyclingIcon />,
  "Mountain Biking": <BakingIcon />,
  Rowing: <RowingIcon />,
  Swimming: <SwimmingIcon />,
  Elliptical: <EllipticalIcon />,
  "Stair Master": <StairsMasterIcon />,
  HIIT: <HitIcon />,
  Crossfit: <CrossfitIcon />,
  Walking: <WalkingIcon />,
  Yoga: <YogaIcon />,
};

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const StaffRole = ["Moderator", "Admin"];

export const VALIDATOR = {
  password: yup
    .string()
    .required("required")
    .min(8, "Password must have a minimum of 8 characters and must contain:")
    .max(20, "Password must have a maximum of 20 characters")
    .matches(AT_LEAST_ONE_LOWERCASE_REGEX, "At least 1 lowercase letter (a-z)")
    .matches(AT_LEAST_ONE_UPPERCASE_REGEX, "At least 1 uppercase letter (A-Z)")
    .matches(AT_LEAST_ONE_NUMBER_REGEX, "At least 1 number (0-9)")
    .matches(
      AT_LEAST_ONE_SPECIAL_CHARACTER_REGEX,
      "At least 1 symbol (?.@!, etc.)"
    )
    .matches(NO_SPACES_REGEX, "No spaces allowed"),
};

export const defaultIngredientsUnit = [
  {
    name: "tsp",
    description: "",
    isDefault: false,
    status: true,
    calories: 0,
    proteins: 0,
    fats: 0,
    carbs: 0,
    fiber: 0,
    alco: 0,
  },
  {
    name: "oz",
    description: "",
    isDefault: false,
    status: true,
    calories: 0,
    proteins: 0,
    fats: 0,
    carbs: 0,
    fiber: 0,
    alco: 0,
  },
  {
    name: "fl oz",

    description: "",
    isDefault: false,
    status: true,
    calories: 0,
    proteins: 0,
    fats: 0,
    carbs: 0,
    fiber: 0,
    alco: 0,
  },
  {
    name: "g",

    description: "",
    isDefault: true,
    status: true,
    calories: 0,
    proteins: 0,
    fats: 0,
    carbs: 0,
    fiber: 0,
    alco: 0,
  },
  {
    name: "tbsp",

    description: "",
    isDefault: false,
    status: true,
    calories: 0,
    proteins: 0,
    fats: 0,
    carbs: 0,
    fiber: 0,
    alco: 0,
  },
  {
    name: "lb",

    description: "",
    isDefault: false,
    status: true,
    calories: 0,
    proteins: 0,
    fats: 0,
    carbs: 0,
    fiber: 0,
    alco: 0,
  },
  {
    name: "ml",

    description: "",
    isDefault: false,
    status: true,
    calories: 0,
    proteins: 0,
    fats: 0,
    carbs: 0,
    fiber: 0,
    alco: 0,
  },
  {
    name: "pinch",

    description: "",
    isDefault: false,
    status: true,
    calories: 0,
    proteins: 0,
    fats: 0,
    carbs: 0,
    fiber: 0,
    alco: 0,
  },
  {
    name: "dash",

    description: "",
    isDefault: false,
    status: true,
    calories: 0,
    proteins: 0,
    fats: 0,
    carbs: 0,
    fiber: 0,
    alco: 0,
  },

  {
    name: "cups",

    description: "",
    isDefault: false,
    status: true,
    calories: 0,
    proteins: 0,
    fats: 0,
    carbs: 0,
    fiber: 0,
    alco: 0,
  },
];

export const BlockReason = [
  "Minor safety",
  "Adult nudity and sexual activities",
  "Bulling and harassment",
  "Illegal activities and regulated goods",
  "Other",
];

export const reportTypes = ["User complaint", "Problem report"];
export const reportCategories = [
  "User's behavior",
  "Bad content",
  "Found issue",
  "Feedback",
  "False information",
];

export const ingredientsCategories = [
  "Beans & Legumes",
  "Beverages",
  "Breads & Cereals",
  "Cheese, Milk & Dairy",
  "Eggs",
  "Fast Food",
  "Fish & Seafood",
  "Fruit",
  "Meat",
  "Nuts & Seeds",
  "Pasta, Rice & Noodles",
  "Salads",
  "Sauces, Spices & Spreads",
  "Snacks",
  "Soups",
  "Sweets, Candy & Desserts",
  "Vegetables",
  "Other",
];

export const RECIPE_CATEGORIES = [
  "Breakfast",
  "Lunch",
  "Dinner",
  "Desserts",
  "Alcohol",
  "Salads",
  "Appetizers/Snacks",
  "Soups/Stews",
  "Main Course/Entrees",
  "Side Dishes",
  "Beverages",
  "Brunch",
];

export const API_NINJA_KEY = "LPrUZ0YN3Ti7dNhjWQIfnA==8sv7LTHeM2FUbU4R";
export const GOOGLE_API_KEY = "AIzaSyDxJ6j8kc8I-BKtoCrC9lXO98bfhfJZ6fs";
export const GOOGLE_SEARCH_ENGINE = "50821d8a1b54143b4";
