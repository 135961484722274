import axios from "axios";
import { BASE_URL } from "../constants";
import {
  getAccessToken,
  getRefreshToken,
  removeAccessToken,
  removeRefreshToken,
  setAccessToken,
  setRefreshToken,
} from "../utils/tokenStorage";
import createAuthRefreshInterceptor from "axios-auth-refresh";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = getAccessToken();

    if (accessToken) {
      //@ts-ignore
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${accessToken}`,
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export const setHeaderToken = (token: string) => {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
};
export const refreshAuth = async (failedRequest: any) => {
  try {
    const refreshToken = getRefreshToken();
    const { data } = await axios.post<{
      success: boolean;
      data: {
        accessToken: string;
        refreshToken: string;
      };
    }>(`admin/refresh`, {
      refreshToken,
    });
    if (data.data.accessToken) {
      failedRequest.response.config.headers.Authorization =
        "Bearer " + data.data.accessToken;
      setHeaderToken(data.data.accessToken);
      setAccessToken(data.data.accessToken);
      setRefreshToken(data.data.refreshToken);
      return await Promise.resolve(data.data.accessToken);
    } else {
      removeAccessToken();
      removeRefreshToken();
      window.location.reload();
      // eslint-disable-next-line prefer-promise-reject-errors
      return await Promise.reject();
    }
  } catch (error) {
    removeAccessToken();
    removeRefreshToken();
    window.location.reload();
  }
};

createAuthRefreshInterceptor(axiosInstance, refreshAuth, {
  statusCodes: [401],
});

export default axiosInstance;
