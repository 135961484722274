import { AxiosResponse } from "axios";
import axiosInstance from "../apis";

export const login = (
  email: string,
  password: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      email,
      password,
    };
    axiosInstance
      .post("admin/login", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const getLinkForUploadImage = (
  fileName: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      fileName,
      type: "admins",
    };
    axiosInstance
      .post("image/admin", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const getMeInfo = (): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get("admin/internal")
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};
