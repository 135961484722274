import { FC, InputHTMLAttributes, useRef } from "react";
import { CrossCircleIcon, CrossIcon, SearchIcon } from "../../assets";
import styles from "./styles.module.scss";

interface Props extends InputHTMLAttributes<HTMLInputElement> {}

const SearchInput: FC<Props> = (props): JSX.Element => {
  const input = useRef<HTMLInputElement>(null);
  return (
    <div className={styles.wrapper}>
      <SearchIcon />
      <input {...props} ref={input} className={styles.input} />

      {props.value ? (
        <div
          onClick={() => props?.onChange?.({ target: { value: "" } } as any)}
          className={styles.crossButton}
        >
          <CrossIcon />
        </div>
      ) : null}
    </div>
  );
};

export default SearchInput;
