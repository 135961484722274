import React, { FC } from "react";
import Modal from "react-modal";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import { CrossIcon, RecipeIcon, ServingIcon, UserIcon } from "../../assets";
import { Tag, RecipeIngredient } from "../../components";
import { IConfigurableIngredient } from "../../pages/Dashboard/CreateRecipe/types";

const RecipePreview: FC<IProps> = ({
  isOpen,
  setIsOpen,
  imagePath,
  title,
  category,
  ingredients,
  description,
  tags,
  preparations,
  numberOfServes,
  gramsPerServe,
  gramsPerServeUnit,
}): JSX.Element => {
  const calculateMacro = (ingredients: IConfigurableIngredient[]) => {
    if (!ingredients) {
      return {
        cal: 0,
        Protein: 0,
        Fat: 0,
        Carbs: 0,
        Fiber: 0,
      };
    }
    const macros = ingredients?.reduce(
      (macro: any, i: any) => {
        const iMacro = i.units?.find((item: any) => item.name === i.unit);

        macro.proteins += +(iMacro?.proteins * i.count).toFixed(2);
        macro.fats += +(iMacro?.fats * i.count).toFixed(2);
        macro.carbs += +(iMacro?.carbs * i.count).toFixed(2);
        macro.fiber += +(iMacro?.fiber * i.count).toFixed(2);
        macro.calories += +(iMacro?.calories * i.count).toFixed(2);
        return macro;
      },
      {
        proteins: 0,
        fats: 0,
        carbs: 0,
        fiber: 0,
        calories: 0,
      }
    );

    const macro = {
      cal: macros?.calories,
      Protein: macros?.proteins,
      Fat: macros?.fats,
      Carbs: macros?.carbs,
      Fiber: macros?.fiber,
    };

    return macro;
  };

  const convertIngredients = (
    ingredient: IConfigurableIngredient
  ): IConfigurableIngredient => {
    let k;

    const ingredeintMacro = ingredient.units.find(
      (item) => item.name === ingredient.unit
    );
    if (ingredeintMacro) {
      ingredient = {
        ...ingredient,
        id: ingredient.id,
        name: ingredient.name,
        category: ingredient.category,
        //@ts-ignore
        proteins: +(ingredeintMacro?.proteins * ingredient.count).toFixed(2),
        //@ts-ignore

        fats: +(ingredeintMacro?.fats * ingredient.count).toFixed(2),
        //@ts-ignore

        carbs: +(ingredeintMacro?.carbs * ingredient.count).toFixed(2),
        //@ts-ignore

        fiber: +(ingredeintMacro?.fiber * ingredient.count).toFixed(2),

        //@ts-ignore
        calories: +(ingredeintMacro?.calories * ingredient.count).toFixed(2),
      };
    }

    return { ...ingredient };
  };
  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <div className={styles.imageWrapper}>
          <div onClick={() => setIsOpen(false)} className={styles.editButton}>
            <CrossIcon />
          </div>
          {imagePath ? (
            <img src={imagePath} className={styles.image} />
          ) : (
            <RecipeIcon className={styles.recipeIcon} />
          )}
          <div className={styles.servingContainer}>
            {numberOfServes && (
              <div className={styles.servingButton}>
                <UserIcon />
                <span className={styles.servingTitle}>{numberOfServes}</span>
              </div>
            )}
            {gramsPerServe && (
              <div className={styles.servingButton}>
                <ServingIcon />
                <span className={styles.servingTitle}>
                  {(+gramsPerServe / (+numberOfServes || 1)).toLocaleString(
                    undefined,
                    {
                      maximumFractionDigits: 2,
                    }
                  )}{" "}
                  {gramsPerServeUnit || "g"}/serving
                </span>
              </div>
            )}
          </div>
        </div>
        <div className={styles.contentContainer}>
          <h3 className={styles.title}>{title}</h3>
          <p className={styles.category}>{category}</p>
          <div className={styles.macroSectionTitle}>Energy per serving:</div>
          <div className={styles.macroContainer}>
            {Object.entries(calculateMacro(ingredients)).map((item, index) => (
              <div key={index} className={styles.macroItem}>
                <span className={styles.macroValue}>
                  {
                    //@ts-ignore
                    (item[1] / (numberOfServes || 1)).toLocaleString(
                      undefined,
                      { maximumFractionDigits: item[0] === "cal" ? 0 : 1 }
                    )
                  }
                  {item[0] !== "cal" ? " g" : ""}
                </span>
                <span className={styles.macroTitle}>{item[0]}</span>
              </div>
            ))}
          </div>
          <p className={styles.description}>{description}</p>
          <div className={styles.tagsContainer}>
            {tags.map((item, index) => (
              <Tag key={index} title={item} />
            ))}
          </div>
          {ingredients?.length > 0 && (
            <>
              <p className={styles.sectionTitle}>Ingredients</p>
              <div className={styles.ingredientsList}>
                {ingredients.map((item, index) => {
                  return (
                    <div key={index}>
                      <RecipeIngredient
                        item={
                          //@ts-ignore
                          convertIngredients(item)
                        }
                      />

                      {index + 1 !== ingredients.length && (
                        <div className={styles.ingredientsDevider} />
                      )}
                    </div>
                  );
                })}
              </div>
            </>
          )}
          <div className={styles.macroSectionTitle}>Total energy:</div>
          <div className={styles.macroContainer}>
            {Object.entries(calculateMacro(ingredients)).map((item, index) => (
              <div key={index} className={styles.macroItem}>
                <span className={styles.macroValue}>
                  {item[1]?.toLocaleString(undefined, {
                    maximumFractionDigits: item[0] === "cal" ? 0 : 1,
                  })}
                  {item[0] !== "cal" ? " g" : ""}
                </span>
                <span className={styles.macroTitle}>{item[0]}</span>
              </div>
            ))}
          </div>
          {preparations?.length > 0 && (
            <>
              <p className={styles.sectionTitle}>Method</p>
              <div className={styles.methodList}>
                {preparations.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className={styles.methodItem}>
                        <div className={styles.iconContainer}>
                          <span>{index + 1}</span>
                        </div>
                        <p className={styles.description}>{item}</p>
                      </div>
                      {index + 1 !== preparations.length && (
                        <div className={styles.methodDevider} />
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default RecipePreview;
