import React, { FC, useRef, useState } from "react";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import { CheckBox, MacroItem, Tag } from "..";
import {
  DotsVertical,
  DublicateIcon,
  PencilIcon,
  RecipeIcon,
  TrashIcon,
} from "../../assets";
import { useOutsideClick } from "../../hooks";
import { motion } from "framer-motion";
import { Delete, RecipePreview } from "../../modals";
import {
  createRecipe,
  deleteRecipe,
  getRecipeById,
  getTags,
} from "../../services/recipe.service";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Recipe from "../../modals/Recipe";
import { ITags } from "../../models";
import { getCategories } from "../../services/category.service";

const RecipeItem: FC<IProps> = ({ item, onGetRecipes }): JSX.Element => {
  const [isShowDelete, setIsShowDelete] = useState<boolean>(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
  const [isLoadingDublicate, setIsLoadingDublicate] = useState<boolean>(false);
  const [isShowPreview, setIsShowPreview] = useState(false);
  const ref = useRef(null);

  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);

  const navigation = useNavigate();

  useOutsideClick(ref, () => setIsOpenMenu(false));

  const macro = {
    cal: item?.calories,
    Protein: item?.proteins,
    Fat: item?.fats,
    Carbs: item?.carbs,
    Fiber: item?.fiber,
    Alco: item?.alco,
    "P/E": item?.pe,
  };

  const onDelete = async () => {
    try {
      setIsLoadingDelete(true);

      const { data } = await deleteRecipe(item.id);

      if (data?.success) {
        onGetRecipes(0, "");
      }
    } catch (error) {
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const onDublicate = async () => {
    try {
      setIsLoadingDublicate(true);
      const { data } = await getRecipeById(item.id);
      let recipe = data?.data?.recipe;
      if (data?.success) {
      }
      const {
        data: {
          data: { tags },
        },
      } = await getTags("");
      const {
        data: {
          data: { categories },
        },
      } = await getCategories("");
      const res = await createRecipe(
        `${recipe?.name} (duplicated)`,
        recipe?.tags.map(
          (item: string) => tags.find((tag: ITags) => tag.value === item).id
        ),
        categories?.find((item: any) => item.value === recipe?.category)?.id,
        recipe?.method || [],
        recipe?.ingredients,
        recipe?.description || "",
        recipe?.image || "",
        recipe?.numberOfServes || 0,
        recipe?.gramsPerServe || "",
        recipe?.gramsPerServeUnit || ""
      );
      if (res?.data?.success) {
        toast.success("Meal has been successfully duplicated");
        onGetRecipes(0, "");
      }
    } catch (error) {
      console.log("error", error);
      toast.error(error?.message?.[0]?.message || "Something went wrong");
    } finally {
      setIsLoadingDublicate(false);
    }
  };

  return (
    <>
      <Recipe
        isOpen={isShowPreview}
        setIsOpen={setIsShowPreview}
        recipeId={item?.id}
        onGetRecipes={onGetRecipes}
      />
      <Delete
        isOpen={isShowDelete}
        setIsOpen={setIsShowDelete}
        onDelete={onDelete}
        isLoading={isLoadingDelete}
        title={"Are you sure you want to delete this recipe?"}
      />
      <div
        onClick={() => setIsShowPreview(true)}
        ref={ref}
        className={styles.wrapper}
      >
        <div className={styles.nameContainer}>
          <div className={styles.imageWrapper}>
            {item?.image ? (
              <img src={item.image} className={styles.image} />
            ) : (
              <RecipeIcon />
            )}
          </div>
          <div style={{ flex: 1 }}>
            <p className={styles.name}>{item.name}</p>
            <div className={styles.macroContainer}>
              {Object.entries(macro).map((item, index) => (
                <MacroItem key={index} title={item[0]} value={item[1]} />
              ))}
            </div>
          </div>
        </div>
        <div className={styles.categoryContainer}>
          <p className={styles.category}>{item?.category}</p>
        </div>
        <div className={styles.tagsContainer}>
          <div className={styles.tagList}>
            {item?.tags?.map((item, index) => (
              <Tag key={index} title={item} />
            ))}
          </div>
          <DotsVertical
            onClick={(e) => {
              e.stopPropagation();

              setIsOpenMenu((prev) => !prev);
            }}
          />
        </div>
        {isOpenMenu && (
          <motion.div
            initial={{ scale: 0.5 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
            }}
            className={styles.menuWrapper}
          >
            <div
              onClick={(e) => {
                e.stopPropagation();
                onDublicate();
                setIsOpenMenu(false);
              }}
              className={styles.menuButton}
            >
              <DublicateIcon
                className={styles.editIcon}
                width={20}
                height={20}
              />
              <span style={{}}>Duplicate</span>
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();

                setIsOpenMenu(false);
                navigation(`/edit-recipe/${item.id}`);
              }}
              className={styles.menuButton}
            >
              <PencilIcon className={styles.editIcon} width={20} height={20} />
              <span style={{}}>Edit</span>
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();

                setIsShowDelete(true);
                setIsOpenMenu(false);
              }}
              className={styles.menuButton}
            >
              <TrashIcon className={styles.deleteIcon} width={20} height={20} />
              <span style={{ color: "#EE3758" }}>Delete</span>
            </div>
          </motion.div>
        )}
      </div>
    </>
  );
};

export default RecipeItem;
