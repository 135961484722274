import { ChangeEvent, FC, useEffect, useState } from "react";

import { IProps } from "./types";
import styles from "./styles.module.scss";
import Modal from "react-modal";
import { Button, Input } from "../../components";
import { useDebounce } from "../../hooks";
import { getGoogleImages } from "../../services/google.service";
import { PulseLoader } from "react-spinners";
import toast from "react-hot-toast";

const Image = ({ item, setImageFile, setImagePath, setIsOpen }: any) => {
  const [error, setError] = useState(false);
  return (
    <img
      onError={() => setError(true)}
      onClick={async () => {
        if (error) {
          return;
        }
        fetch(item.link)
          .then((res) => res.blob())
          .then((blob) => {
            let objectURL = URL.createObjectURL(blob);
            setImagePath(objectURL);
            setImageFile(objectURL);
            setIsOpen(false);
          })
          .catch((e) => {
            setImagePath(item.link);
            setImageFile();
            setIsOpen(false);
          });
      }}
      key={item?.link}
      src={item?.link}
      alt="image"
      className={styles.image}
    />
  );
};

const GoogleImagesSearch: FC<IProps> = ({
  isOpen,
  setIsOpen,
  setImagePath,
  setImageFile,
}): JSX.Element => {
  const [search, setSearch] = useState("");
  const [images, setImages] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPagination, setIsLoadingPagination] = useState(false);

  const debounceSearch = useDebounce(search, 600);

  const onGetImages = async (isLoadingPagination: boolean = false) => {
    try {
      if (isLoadingPagination) {
        setIsLoadingPagination(true);
      } else {
        setIsLoading(true);
      }

      const { data } = await getGoogleImages(
        debounceSearch,
        images.length > 0 ? images.length + 1 : 0
      );
      if (data?.items) {
        if (isLoadingPagination) {
          setImages((prev) => [...prev, ...data?.items]);
        } else {
          setImages(data?.items);
        }
      }
    } catch (error) {
      toast.error(error?.error?.message || "Something went wrong");
    } finally {
      setIsLoadingPagination(false);
      setIsLoading(false);
    }
  };

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setImages([]);
    setSearch(e.target.value);
  };

  useEffect(() => {
    setSearch("");
    setImages([]);
  }, [isOpen]);

  useEffect(() => {
    if (debounceSearch) {
      onGetImages(!!images?.length);
    }
  }, [debounceSearch]);

  const handleScroll = (e: any) => {
    const bottom =
      Math.abs(
        e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)
      ) <= 1;
    if (bottom && !isLoading && !isLoadingPagination) {
      // onGetIngredients(+page + 1, debounceSearch);
      // setPage((prev) => prev + 1);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <Input
          placeholder={"Search"}
          value={search}
          onChange={onChangeSearch}
        />
        {!debounceSearch && !images.length && (
          <div className={styles.loaderWrapper}>
            <span>Enter something in the search to see the result.</span>
          </div>
        )}

        {isLoading && (
          <div className={styles.loaderWrapper}>
            <PulseLoader color={"#7D4AFB"} />
          </div>
        )}

        {!isLoading && (
          <div onScroll={handleScroll} className={styles.imagesContainer}>
            {images?.map((item: any) => (
              <Image
                item={item}
                setImageFile={setImageFile}
                setImagePath={setImagePath}
                setIsOpen={setIsOpen}
              />
            ))}
          </div>
        )}
        {!isLoadingPagination && images.length < 100 && !!images.length && (
          <div className={styles.loadMoreContainer}>
            <Button
              size="small"
              styleType="filled"
              title="Load More"
              style={{ width: "150px" }}
              onClick={() => onGetImages(true)}
            />
          </div>
        )}
        {isLoadingPagination && (
          <div className={styles.loadMoreContainer}>
            <PulseLoader color={"#7D4AFB"} />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default GoogleImagesSearch;
