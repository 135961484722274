import React, { ChangeEvent, FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import { CrossIcon, RefreshIcon } from "../../assets";
import { Button, Input, Selector, TagSelector } from "../../components";
import { createCategory } from "../../services/category.service";
import { toast } from "react-hot-toast";

const CreateCategory: FC<IProps> = ({
  isOpen,
  setIsOpen,
  onGetCategories,
}): JSX.Element => {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);

  useEffect(() => {
    setName("");
    setNameError("");
  }, [isOpen]);

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onCreateCategory = async () => {
    try {
      setIsLoadingCreate(true);

      const { data } = await createCategory(name);
      if (data.success) {
        onGetCategories();
        setIsOpen(false);
        setName("");
        setNameError("");
        toast.success("Category was successfully created");
      } else {
        setNameError("Сategory already exists");
      }
    } catch (error) {
      setNameError(
        error?.response?.data?.message?.[0]?.message || "Something went wrong"
      );
    } finally {
      setIsLoadingCreate(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <h6>Create New Category</h6>
        <div onClick={() => setIsOpen(false)} className={styles.closeButton}>
          <CrossIcon />
        </div>
        <div className={styles.inputContainer}>
          <Input
            value={name}
            onChange={onChangeName}
            placeholder="Category name"
            error={nameError}
            maxLength={50}
          />
        </div>

        <Button
          title="Create"
          size="medium"
          styleType={name.length < 2 ? "disabled" : "filled"}
          disabled={name.length < 2}
          loading={isLoadingCreate}
          onClick={onCreateCategory}
        />
      </div>
    </Modal>
  );
};

export default CreateCategory;
